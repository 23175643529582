<template>
  <div class="index">
    <div class="shopro-form-contaner">
      <van-form>
        <van-field
          v-model="name"
          name="姓名"
          label="姓名"
          placeholder="请输入姓名"
        ></van-field>
        <van-field
          v-model="phone"
          type="number"
          name="手机号"
          label="手机号"
          placeholder="请输入手机号"
        ></van-field>
        <van-field
          v-model="id_card"
          name="身份证号"
          label="身份证号"
          placeholder="请输入身份证号"
        ></van-field>
        <van-field
          readonly
          clickable
          name="calendar"
          :value="value"
          label="预约时间"
          placeholder="点击选择预约时间"
          @click="selectTime"
        ></van-field>
        <div class="agreement-item">
          <van-radio v-model="radio" name="1">
            <span>勾选代表同意<span @click="change">《参观须知》</span></span>
          </van-radio>
        </div>
      </van-form>
    </div>
    <div class="shopro-submit-containerd" v-if="hideshow">
      <div class="shopro-submit-button" @click="submitForm">提交预约</div>
      <div class="shopro-query-button" @click="goQuery">查询我的预约</div>
    </div>

    <van-calendar
      v-model="showCalendar"
      @confirm="onConfirm"
      :min-date="minDate"
      :max-date="maxDate"
    ></van-calendar>
    <!-- 协议弹出 -->
    <van-overlay :show="agreementShow"></van-overlay>
    <div class="agreement" v-if="agreementShow">
      <div class="agreement-container">
        <div class="agreement-title">参观须知</div>
        <div class="agreement-explain" v-html="agreementHtml"></div>
      </div>
      <div class="agreement-image">
        <van-image src="image/de-bj.png"></van-image>
      </div>
      <div class="agreement-button-container">
        <div class="agreement-button-close" @click="agreementClose">
          <van-image src="image/close.png"></van-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

function getDateStr(AddDayCount) {
  var dd = new Date();
  dd.setDate(dd.getDate() + AddDayCount); 
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1; 
  var d = dd.getDate();
  return y + "-" + (m < 10 ? "0" + m : m) + "-" + d;
}
export default {
  name: "Index",
  data() {
    return {
      agreementHtml: "",

      name: "",
      phone: "",
      id_card: "",
      value: "",
      showCalendar: false,
      radio: 0,
      show: false,

      agreementShow: false,
      submitSucessShow: false,

      minDate: new Date(
        getDateStr(0).split("-")[0],
        getDateStr(0).split("-")[1] - 1,
        getDateStr(0).split("-")[2]
      ),
      maxDate: new Date(
        getDateStr(1).split("-")[0],
        getDateStr(1).split("-")[1] - 1,
        getDateStr(1).split("-")[2]
      ),
      hideshow: true,
      docmHeight: document.documentElement.clientHeight, 
      showHeight: document.documentElement.clientHeight,
    };
  },
  mounted() {
    this.getInit();
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight;
      })();
    };
  },
  methods: {
    getInit() {
      let that = this;
      that.$axios.get("https://huanghe.xprings.com/api/apply/init").then((res) => {
        that.agreementHtml = res.data.data.protocol.content;
      });
    },
    agreementClose() {
      this.agreementShow = false;
    },
    change() {
      this.agreementShow = true;
    },
    selectTime() {
      this.showCalendar = true;
      this.$forceUpdate();
    },
    submitForm() {
      let that = this;
      if (that.radio == 0) {
        Toast("请勾选协议");
      } else {
        that.$axios
          .post("https://huanghe.xprings.com/api/apply/save", {
            name: that.name,
            phone: that.phone,
            id_card: that.id_card,
            apply_date: that.value,
          })
          .then((res) => {
            if (res.data.code == -1) {
              that.$router.push({
                path: "/error",
                query: { message: res.data.msg, from: "index" },
              });
            } else if (res.data.code == 0) {
              Toast(res.data.msg);
            } else {
              this.$router.push({
                path: "/success",
                query: { message: res.data },
              });
            }
          });
      }
    },
    onConfirm(date) {
      let d = new Date(date);
      this.value = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
      this.showCalendar = false;
    },
    goQuery() {
      this.$router.push("/req");
    },
  },
  watch: {
    showHeight: function () {
      if (this.docmHeight > this.showHeight) {
        this.hideshow = false;
      } else {
        this.hideshow = true;
      }
    },
  },
};
</script>
<style lang="scss">
.shopro-form-contaner {
  padding: 0 14px;
}

.van-field__label {
  width: 3.6rem;
}

.agreement-item {
  margin-top: 20px;
  color: #ffa700;
  font-size: 12px;

  .van-radio__label {
    color: #ffa700;
    font-size: 12px;
  }

  .van-radio__icon {
    height: auto;
    line-height: 0.8rem;
  }

  .van-radio__icon--checked .van-icon {
    color: #ffa700;
    background-color: #fff;
  }

  .van-radio__icon .van-icon {
    border-color: #ffa700;
    font-size: 0.6em;
  }
}

.shopro-submit-containerd {
  position: absolute;
  bottom: 25px;
  width: 100%;
}

.shopro-submit-button,
.shopro-query-button {
  width: auto;
  height: 43px;
  line-height: 43px;
  text-align: center;
  color: #fff;
  background: linear-gradient(90deg, #ffae00, #ff9000);
  box-shadow: 0px 7px 11px 2px rgba(255, 168, 0, 0.17);
  border-radius: 43px;
  font-size: 14px;
  margin: 0 14px;
}

.shopro-query-button {
  margin-top: 12px;
  box-shadow: none;
  border: 1px solid #ff9800;
  background: #fff;
  color: #ff9600;
}

.van-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.agreement {
  position: absolute;
  background: rgba(0, 0, 0, 0);
  width: 315px;
  height: 426px;
  left: 50%;
  top: 50%;
  margin-left: -157.5px;
  margin-top: -213px;
  z-index: 200;
}

.agreement-image {
  position: absolute;
  top: 30px;
  right: 10px;
  width: 170px;
  height: 112px;
  z-index: 0;
}

.agreement-container {
  width: 315px;
  height: 426px;
  background: linear-gradient(0deg, #ffffff 0%, #fff5e3 100%);
  border-radius: 10px;
  padding: 22px 27px 28px 22px;
  box-sizing: border-box;
}

.agreement-title {
  margin-bottom: 22px;
  color: #333;
  font-size: 17px;
  font-weight: 600;
}

.agreement-explain {
  overflow: auto;
  height: 340px;
}

.agreement-button-close {
  width: 30px;
  height: 30px;
}

.agreement-button-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.van-calendar__selected-day,
.van-button--danger {
  background-color: #ffa700;
  border-color: #ffa700;
}
</style>
